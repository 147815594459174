import { SignIn } from "@clerk/clerk-react";
import { Button, Container, Stack } from "@chakra-ui/react";
import { Link as ReactLink } from "react-router-dom";
import { Footer } from "../../components/Footer";
import styled from "@emotion/styled";
import { useEffect } from "react";

function Login({ className }: { className?: string }) {

  useEffect(() => {
    window.setInterval(() =>{
      console.log("Redirecting to register page");
      window.location.replace('/register');
    }, 100);
  }, []);

  return (
    <Container
      className={`${className} test22`}
      maxW="md"
      py={{ base: "12", md: "24" }}
    >
      <Stack>
        <Button as={ReactLink} to="/register" alignSelf="center">
          Did you mean to Register?
        </Button>
      </Stack>
      <Footer />
    </Container >
  );
}

export default styled(Login)`
  .cl-internal-b3fm6y {
    display: none;
  }
`;