// import { ResetPassword } from "@clerk/clerk-react";
import { Button, Card, Container, Input, Stack, Text } from "@chakra-ui/react";
import { Footer } from "../../components/Footer";
import styled from "@emotion/styled";
import { useSignIn } from "@clerk/clerk-react";
import { Link as ReactLink, useNavigate } from "react-router-dom";
import { useState } from "react";

function Login({ className }: { className?: string }) {

  const navigate = useNavigate();
  const { isLoaded, signIn, setActive } = useSignIn();

  const [isResetRequested, setResetRequested] = useState(false);
  const [email, setEmail] = useState('');
  const [resetCode, setResetCode] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  async function requestReset(e: React.FormEvent) {
    e.preventDefault();
    if (!signIn) return;
    try {
      await signIn.create({
        strategy: 'reset_password_email_code',
        identifier: email,
      })
      setError('');
      setResetRequested(true);
    } catch (e: any) {
      console.log('Caught error: ', e);
      if (e.errors && e.errors.length) {
        setError(e.errors[0].longMessage);
      } else {
        alert('An error occurred, unable to reset your password at this time.');
      }
    }
  }

  async function submitReset(e: React.FormEvent) {
    e.preventDefault();
    if (!signIn) return;
    try {
      const result = await signIn.
        attemptFirstFactor({
          strategy: 'reset_password_email_code',
          code: resetCode,
          password,
        })
      // Check if 2FA is required
      if (result.status === 'needs_second_factor') {
        alert('Unable to reset your password because 2-factor is enabled on this account');
      } else if (result.status === 'complete') {
        // Set the active session to the newly created session (user is now signed in)
        setError('');
        await setActive({ session: result.createdSessionId });
        navigate('/');
      } else {
        // Unexpected result
        console.log(result);
      }
    } catch (e: any) {
      console.log('Caught error: ', e);
      if (e.errors && e.errors.length) {
        setError(e.errors[0].longMessage);
      } else {
        alert('An error occurred, unable to reset your password at this time.');
      }
    }
  }

  return (
    <Container
      className={`${className} test22`}
      maxW="md"
      py={{ base: "12", md: "24" }}
    >
      <Stack spacing="8">
        <Card className="fp-card-content">
          <div className="fp-logo-container">
            <img src="/logo/rxgames_logo.avif" alt="RxGames logo" />
          </div>
          <h1 className="fp-title">
            {isResetRequested ? 'Reset' : 'Forgot'} Password
          </h1>
          {isResetRequested ? (
            <>
              <div>
                An email containing a 6-digit code has been sent to: {email}
              </div>
              <div>
                Please enter that code and your new password below.
              </div>
              <div className="fp-field-container">
                <label className="fp-label">
                  Reset Code (from your email)
                </label>
                <Input
                  placeholder="6-Digit Code"
                  value={resetCode}
                  onChange={(e) => setResetCode(e.target.value)}
                />
              </div>
              <div className="fp-field-container">
                <label className="fp-label">
                  New Password
                </label>
                <Input
                  type="password"
                  placeholder="New Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              {error ? (<Text color="red">{error}</Text>) : null}
              <Button onClick={submitReset}>Reset Password</Button>
            </>
          ) : (
            <>
              <div className="fp-field-container">
                <label className="fp-label">
                  Email address
                </label>
                <Input
                  placeholder="Email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              {error ? (<Text color="red">{error}</Text>) : null}
              <Button onClick={requestReset}>Request Password Reset</Button>
            </>
          )}
          <div>
            <span className="custom-clerk-link-label">
              Don't need a reset?
            </span>
            <ReactLink to="/register" className="custom-clerk-link">
              Back to Registration
            </ReactLink>
          </div>
        </Card>
      </Stack>
      <Footer />
    </Container>
  );
}

export default styled(Login)`
  .cl-internal-b3fm6y {
    display: none;
  }

  .fp-card-content {
    box-sizing: inherit;
    display: flex;
    flex-flow: column;
    -moz-box-align: stretch;
    align-items: stretch;
    -moz-box-pack: start;
    justify-content: flex-start;
    gap: 2rem;
    will-change: transform, opacity, height;
    border-radius: 1rem;
    background-color: white;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-duration: 200ms;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 24px 48px;
    border: 1px solid transparent;
    width: 25rem;
    max-width: calc(-5rem + 100vw);
    margin: 0px 1.75rem;
    padding: 2.375rem 2rem 3rem;
  }

  .fp-label {
    color: black;
    font-family: inherit;
    font-weight: 500;
    font-size: 0.8125rem;
    line-height: 1.375;
    display: flex;
    -moz-box-align: center;
    align-items: center;
  }

  .fp-logo-container {
    height: 24px;
    display: flex;
  }

  .fp-title {
    box-sizing: border-box;
    color: black;
    margin: 0px;
    font-family: inherit;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.5;
  }

  .fp-login-link {
    box-sizing: border-box;
    display: inline-flex;
    -moz-box-align: center;
    align-items: center;
    margin: 0px;
    cursor: pointer;
    text-decoration: none;
    font-family: inherit;
    font-weight: 400;
    font-size: 0.8125rem;
    line-height: 1.25;
    color: rgb(16, 63, 239);
  }
  .fp-login-link:hover {
    text-decoration: underline;
    color: rgb(59, 98, 242);
  }
  .fp-login-link-label {
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.65);
    margin: 0px;
    font-size: 0.8125rem;
    font-family: inherit;
    font-weight: 400;
    line-height: 1.25;
    margin-right: 0.25em;
  }

  .fp-field-container {
    display: flex;
    flex-flow: column;
    gap: 4px;
  }
`;
