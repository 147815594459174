import { Container, Flex, Stack, Text } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { useEffect } from "react";
import { hasToken } from "../../../lib/store";
import { UserType } from "../../../types/enum";
import { getUser, setRoleToPatient } from "../../../api";

function Home({ className }: { className?: string }) {

  function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const setUserAsPatient = async () => {
    // Ensure the Auth Token is loaded before trying to get the user profile information
    const maxTries = 30;
    for (let i = 0; i < maxTries; i++) {
      const res = hasToken();
      if (res) break;
      await sleep(100);
    }
    const user = await getUser();
    // If the user is not a patient type/role yet, update them such that they are
    if (user.type != UserType.PATIENT || !user.roles.includes(UserType.PATIENT)) {
      setRoleToPatient();
    }
  }

  useEffect(() => {
    setUserAsPatient();
  });


  return (
    <Container className={className} maxW="md" py={{ base: "12", md: "24" }} flexGrow="2">
      <Stack spacing="8">
        <Flex alignItems="center" flexDir="column" py={6} textAlign="center">
          <Text fontSize="md" fontWeight="bold">
            You are now registered with RxGames. Thanks for signing up!
          </Text>
        </Flex>
      </Stack>
    </Container>
  );
}

export default styled(Home)`
  .cl-internal-b3fm6y {
    display: none;
  }
`;
