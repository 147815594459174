import * as React from "react";
import { Flex, FlexProps } from "@chakra-ui/react";
import Logo1x from "../assets/images/RxGames_Logo_Primary.png";
import Logo2x from "../assets/images/RxGames_Logo_Primary@2x.png";

interface LogoProps extends FlexProps {}

function Logo(props: LogoProps) {
  return (
    <Flex {...props}>
      <img
        src={Logo1x}
        srcSet={`${Logo1x} 0.25x, ${Logo2x} 0.5x`}
        alt="RxGames Logo"
      />
    </Flex>
  );
}

export { Logo };
