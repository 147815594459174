const USER_TOKEN = "USER_TOKEN";
const USER_TOKEN_EXPIRES = "USER_TOKEN_EXPIRES";
const ONE_DAY = 1000 * 60 * 60 * 24;

export const cacheToken = (token: string) => {
  localStorage.setItem(USER_TOKEN, token);
  localStorage.setItem(USER_TOKEN_EXPIRES, new Date().getTime().toString());
};

export const loadToken = () => {
  return localStorage.getItem(USER_TOKEN);
};

export const hasToken = () => {
  const expires = localStorage.getItem(USER_TOKEN_EXPIRES);
  if (expires) {
    const now = new Date().getTime();
    const diff = now - parseInt(expires);
    if (diff > ONE_DAY) {
      return false;
    }
  }
  return !!loadToken();
};

export const clearToken = () => {
  localStorage.removeItem(USER_TOKEN);
  localStorage.removeItem(USER_TOKEN_EXPIRES);
};
