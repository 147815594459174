const colors = {
  brand: {
    black: "#10102a",
    purple: "#292c6d",
    blue: "#4b6eff",
    green: "#00e6a5",
    orange: "#ff5a00",
    gray: "#676767",
    red: "#E53E3E",
  },
};

const components = {
  Button: {
    baseStyle: {},
    variants: {
      solid: {
        bg: "brand.blue",
        color: "white",
        _hover: {
          bg: "brand.black",
        },
      },
      ghost: {
        bg: "transparent",
        color: "brand.black",
        _hover: {
          bg: "transparent",
        },
        _active: {
          bg: "transparent",
        },
      },
    },
  },
};

const theme = {
  colors,
  components,
};

export default theme;
