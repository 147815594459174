import {
  Box,
  Button,
  Text,
  ButtonGroup,
  Container,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Logo } from "../../../components/Logo";
import { Sidebar } from "./Sidebar";
import { ToggleButton } from "./ToggleButton";
import { clearToken } from "../../../lib/store";
import { useUser } from "@clerk/clerk-react";
import { SignOutButton, useClerk } from "@clerk/clerk-react";
import styled from "@emotion/styled";
interface NavbarProps {
  user?: User;
}

// button:nth-child(2) {
const StyledButtonGroup = styled(ButtonGroup)`
  button:nth-of-type(2) {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    white-space: nowrap;
    vertical-align: middle;
    outline: 2px solid transparent;
    outline-offset: 2px;
    line-height: 1.2;
    border-radius: var(--chakra-radii-md);
    font-weight: var(--chakra-fontWeights-semibold);
    transition-property: var(--chakra-transition-property-common);
    transition-duration: var(--chakra-transition-duration-normal);
    height: var(--chakra-sizes-10);
    min-width: var(--chakra-sizes-10);
    font-size: var(--chakra-fontSizes-md);
    -webkit-padding-start: var(--chakra-space-4);
    padding-inline-start: var(--chakra-space-4);
    -webkit-padding-end: var(--chakra-space-4);
    padding-inline-end: var(--chakra-space-4);
    color: var(--chakra-colors-brand-black);
    background: var(--chakra-colors-transparent);
    &:hover {
      var(--chakra-colors-transparent);
    }
  }
`;

export const Navbar = ({ user }: NavbarProps) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const { isOpen, onToggle, onClose } = useDisclosure();
  const navigate = useNavigate();
  const { signOut } = useClerk();

  const handleSignOut = async () => {
    await signOut();
    clearToken();
  };

  const fullName = `${user?.firstName} ${user?.lastName}`;
  const clerkUser = useUser();

  return (
    <Box as="nav" bg="bg-accent" color="on-accent">
      <Container maxW="6xl" py={{ base: "3", lg: "4" }}>
        {isDesktop && (
          <Flex justifyContent="flex-end" mr={4}>
            <Text fontSize="sm" color="gray.500">
              {clerkUser.user?.fullName}
            </Text>
          </Flex>
        )}
        <Flex justify="space-between">
          <HStack spacing="1">
            <Logo maxW={150} onClick={() => navigate("/")} cursor="pointer" />
          </HStack>
          {isDesktop ? (
            <StyledButtonGroup variant="ghost" spacing="1">
              <SignOutButton signOutCallback={handleSignOut}>
                Sign Out
              </SignOutButton>
            </StyledButtonGroup>
          ) : (
            <>
              <ToggleButton
                isOpen={isOpen}
                aria-label="Open Menu"
                onClick={onToggle}
              />
              <Drawer
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                isFullHeight
                preserveScrollBarGap
                // Only disabled for showcase
                trapFocus={false}
              >
                <DrawerOverlay />
                <DrawerContent>
                  <Sidebar name={fullName} />
                </DrawerContent>
              </Drawer>
            </>
          )}
        </Flex>
      </Container>
    </Box>
  );
};
