import * as React from "react";
import {
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
} from "react-router-dom";
import { AuthGuard } from "./components/AuthGuard";

import Login from "./containers/Login";
import Register from "./containers/Register";
import ForgotPassword from "./containers/ForgotPassword";
import Dashboard from "./containers/Dashboard";
import Home from "./containers/Dashboard/Home";
import NotFound from "./containers/NotFound";
import { AuthenticateWithRedirectCallback } from "@clerk/clerk-react";

const routes = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <Dashboard />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        // index:true,
        element: (
          <AuthGuard>
            <Home />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "/login/sso-callback",
    element: <AuthenticateWithRedirectCallback />,
  },
  {
    path: "/login/*",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/register/verify-email-address",
    element: <Register />,
  },
  {
    path: "/forgot-password/*",
    element: <ForgotPassword />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];


function AppRoutes() {
  return (
    <Routes>
      {routes.map((route) => (
        <Route path={route.path} key={route.path} element={route.element}>
          {route.children &&
            route.children.map((childRoute) => (
              <Route path={childRoute.path} element={childRoute.element} key={childRoute.path} />
            ))}
        </Route>
      ))}
    </Routes>
  );
}

export default AppRoutes;
