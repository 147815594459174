import * as React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { Box, Container } from "@chakra-ui/react";
import { Navbar } from "./components/Navbar";
import { Footer } from "../../components/Footer";
import { useQuery } from "react-query";
import { hasToken } from "../../lib/store";
import { getUser } from "../../api";

function Dashboard() {
  const { data } = useQuery<User, Error>("user", getUser);

  return (
    <Box className="Home" as="section" bg={{ base: "gray.50" }} minH="100vh" display="flex" flexDirection="column">
      <Navbar user={data} />
      <Container
        maxW="6xl"
        flex="2"
        display="flex"
        justifyContent="center"
        alignItems="center"
        pt={{ base: 8, lg: 12 }}
        pb={{ base: 12, lg: 24 }}
        px={{ base: 4, md: 8, xl: 0 }}
      >
        <Outlet />
      </Container>
      <Footer />
    </Box>
  );
}

export default Dashboard;
